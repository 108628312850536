import {Component, HostListener, OnInit} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/de';
import {environment} from '../environments/environment';
import {ElementOptions, ElementsOptions, StripeService} from '@nomadreservations/ngx-stripe';
import {PaymentService} from './services/payment.service';
import {AngularFirestore} from '@angular/fire/firestore';


declare var Stripe: any;
registerLocaleData(localeFr, 'de');

// https://github.com/nomadreservations/ngx-stripe

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isLoading = false;
  amountOfCards = 0;

  title: string;
  subtitle: string;
  text: string;
  footerNote: string;

  donateMore = null;
  amount = 25;
  email = '';
  disableButton = false;

  total = 0;
  completed = 0;

  payments: any;
  cardsAvailable = 247;

  checkout = false;

  stripeKey = environment.stripeKey;
  error: any;
  complete = false;
  element: any;
  cardOptions: ElementOptions = {
    style: {
      base: {
        // iconColor: '#276fd3',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: ElementsOptions = {
    locale: 'de'
  };

  constructor(private stripeService: StripeService, private paymentService: PaymentService, private db: AngularFirestore) {
  }

  toogle() {
    this.donateMore = null;
    this.checkout = false;
  }


  cardUpdated(result) {
    this.element = result.element;
    this.complete = result.card.complete;
    if (this.complete) {
      // tslint:disable-next-line:no-unused-expression
      // this.stripeService;
    }
    this.error = undefined;
  }

  keyUpdated() {
    this.stripeService.changeKey(this.stripeKey);
  }

  async payNow() {
    this.isLoading = true;
    if (this.amount >= 25) {
      this.disableButton = true;
      let finalAmount = 2500;

      if (this.donateMore != null && this.donateMore !== '') {
        finalAmount = (this.amount + parseInt(this.donateMore, 0)) * 100;
      }

      try {
        const response = await fetch(environment.intentApi, {
          method: 'POST',
          body: JSON.stringify({amount: finalAmount, currency: 'eur', email: this.email}),
          headers: {
            'content-type': 'application/json',
          },
        });

        const body = await response.json();

        this.stripeService.handleCardPayment(body.client_secret, this.element, {
          payment_method_data: {
            billing_details: {name: this.email, email: this.email}
          }
        }).subscribe(result => {
          if (result.error) {
            alert(result.error.message);
            this.disableButton = false;
            this.isLoading = false;
          } else {

            this.checkout = true;
            this.disableButton = false;
            this.paymentService.processPayment(body, result, finalAmount, this.amountOfCards);
            this.paymentService.updateStats(finalAmount, this.amountOfCards);
            this.isLoading = false;

          }
        });
      } catch (e) {
        alert(e);
        this.isLoading = false;
        this.disableButton = false;
      }
    } else {
      this.isLoading = false;
    }

  }

  ngOnInit(): void {
    this.getData();
    this.getContent();
  }

  getData() {
    this.db.collection('statistics').valueChanges().subscribe(data => {
      this.payments = data;
      let sum = 0;
      let sumOfCard = 0;
      this.payments.forEach((payment) => {
        sum = sum + payment.amount;
        if (payment.amountOfCards != null) {
          sumOfCard = sumOfCard + payment.amountOfCards;
        }

      });
      this.cardsAvailable = 247 - sumOfCard;
      this.completed = ((sum / 100) / 20000) * 100;
    });
  }


  getContent() {
    this.db.collection('content').doc('main').valueChanges().subscribe((data: any) => {
      this.title = data.title;
      this.subtitle = data.subtitle;
      this.text = data.text;
      this.footerNote = data.footerNote;
    });
  }

}
