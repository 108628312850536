// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appToken: 'cd84e7b4-5693-4275-a086-8986f5a661a5',
  stripeKey: 'pk_test_02aAGf8JwLvxTMZSgQ88tqHn',
  firebaseConfig : {
    apiKey: 'AIzaSyARLVyU_UUJx31ZwyvKONK3No_TbrxPmEI',
    authDomain: 'charity-app-1bce1.firebaseapp.com',
    databaseURL: 'https://charity-app-1bce1.firebaseio.com',
    projectId: 'charity-app-1bce1',
    storageBucket: 'charity-app-1bce1.appspot.com',
    messagingSenderId: '854119771756',
    appId: '1:854119771756:web:bc087003b29c18eca27249',
    measurementId: 'G-TL65D3RSXP'
  },
  intentApi: 'https://charity.david-krachler.com/api/pay'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
